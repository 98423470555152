import './GitHubInfo.css';
import React, { useEffect, useState } from "react";
import Axios from "axios";

const API_REPOSITORY_ENDPOINT = 'https://api.github.com/users/nemjit001/repos'

async function fetchRepositories()
{
    let response = await Axios.get(API_REPOSITORY_ENDPOINT);
    if (response.status !== 200)
        throw new Error("Failed to fetch repositories")

    let repo_list = response.data.filter((repo) => repo.name !== "nemjit001")
    return repo_list;
}

function GitHubInfo()
{
    const [ repositories, setRepositories ] = useState([]);

    useEffect(() => {
        fetchRepositories()
            .then((repos) => setRepositories(repos))
            .catch(() => setRepositories([]))
    }, [])

    return (
        <section className="github-info-section">
            <h2>curl --public --contributed git.repositories</h2>
            <div className="github-info-flex-container">
                {repositories.map((repo, idx) =>
                    <div className="github-info-flex-child" key={idx}>
                        <h3>{repo.name} {repo.fork ? "(Fork)" : ""} {repo.archived ? "(Archived)" : ""}</h3>
                        <p className="github-info-description">{repo.description ?? "No Description"}</p>
                        <a href={repo.html_url} className="link">repository_link</a>
                    </div>
                )}
            </div>
        </section>
    );
}

export default GitHubInfo;
