import './CTABlurb.css'
import navigate_down_arrow from './navigate-down-arrow.svg'

function CTABlurb() {
    return (
        <div className="cta-blurb">
            <h1 className="name-blurb">Tijmen Verhoef's Portfolio Website<span className="blink">_</span></h1>
            <a href="#bio">
                <img className="scroll-blurb" alt="scroll down" src={navigate_down_arrow} width="75" height="75"></img>
            </a>
        </div>
    );
}

export default CTABlurb;
