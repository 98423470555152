import './Bio.css'
import photo_tverhoef from './photo_tverhoef.jpg'

const JOB_TITLE = 'Master Game & Media Technology'

const WORKPLACE = 'Utrecht University'

function BioText() {
    return (
        <div className="bio-flex-child">
            <p>
                Hi! I am a Software Engineer with a BSc. in Computer Science from the VU Amsterdam.
            </p>
            <p>
                I am currently working towards my <span className="bio-work-span">{JOB_TITLE}</span> at <span className="bio-work-span">{WORKPLACE}</span>.
                In my free time I enjoy working on hobby projects and making music, as well as meeting up with friends.
            </p>
            <p>
                My personal projects and any open source contributions are listed below!
            </p>
        </div>
    );
}

function Bio() {
    return (
        <section id="bio" className="bio-section">
            <h2>cat AboutMe.txt</h2>
            <div className="bio-flex-container">
                <img className="bio-img bio-flex-child" src={photo_tverhoef} alt="Tijmen Verhoef" width="150" height="150"/>
                <BioText />
            </div>
        </section>
    );
}

export default Bio;
