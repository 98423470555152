import './App.css'
import React from 'react'
import Portfolio from './Portfolio/Portfolio'

class App extends React.Component {
    render() {        
        return (
            <main>
                <Portfolio />
            </main>
        );
    }
}

export default App;
