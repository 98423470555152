import './Portfolio.css'
import React from 'react'
import CTABlurb from './CTABlurb/CTABlurb'
import Bio from './Bio/Bio'
// import Projects from './Projects/Projects'
import SocialMediaLinks from './SocialMediaLinks/SocialMediaLinks'
import GitHubInfo from './GitHubInfo/GitHubInfo'

class Portfolio extends React.Component {
    render() {
        return (
            <div className="portfolio-container">
                <CTABlurb />
                <div className="main-content">
                    <Bio />
                    {/* TODO: uncomment when actual websites of other projects go up <Projects /> */}
                    <GitHubInfo />
                    <SocialMediaLinks />
                </div>
            </div>
        );
    }
}

export default Portfolio;
