import './SocialMediaLinks.css'
import GitHub from './GitHub-Mark-Light-64px.png'
import LinkedIn from './LI-In-Bug.png'

function SocialMediaLinks()
{
    return (
        <section className="social-media-section">
            <h2>./SocialMedia --help</h2>
            <p>Hey! I can be reached on the following platforms :)</p>
            <div className="social-media-container">
                <a className="link social-media-link" target="_blank" rel="noreferrer" href="https://www.github.com/nemjit001">
                    <img src={GitHub} alt="GitHub" width="50" height="50"/>
                </a>
                <a className="link social-media-link" target="_blank" rel="noreferrer" href="https://www.linkedin.com/in/tijmen-verhoef/">
                    <img src={LinkedIn} alt="LinkedIn" width="50" height="50"/>
                </a>
            </div>
        </section>
    );
}

export default SocialMediaLinks;
